import NextImage from 'next/image'
import T from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import { getImageUrl } from '../../api/sanity'
import BEMHelper from '../../helpers/bem'
import Modal from '../Modal'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

const SIZES = {
  default: 1260,
  small: 800,
  medium: 1260,
  large: 1560,
}
export default function Image({
  type,
  alt,
  caption,
  dimensions,
  lqip,
  className,
  facsimile,
  expandable,
  link,
  download,
  source,
  ...props
}) {
  const imageRef = useRef()
  const triggerRef = useRef()
  const [loading, setLoading] = useState(true)
  const [expanded, setExpanded] = useState(false)
  const toggle = (value) => () => {
    if (value) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  const image = getImageUrl(props)
  const width = props?.width || SIZES[type] || SIZES.default
  const aspect =
    props?.height && props?.width ? props?.width / props?.height : dimensions?.aspectRatio
  const src = props?.height
    ? image.width(width).height(props?.height).url()
    : image.width(width).url()

  const tallImage = aspect < 1.2
  const styles = {
    backgroundImage: lqip && loading ? `url(${lqip})` : null,
    backgroundColor: loading ? 'rgba(0, 0, 0, 0.5)' : null,
  }

  const handleLoad = () => setLoading(false)

  useEffect(() => {
    if (imageRef.current.complete) setLoading(false)
  }, [])

  const extraProps = expandable ? { onClick: toggle(true), type: 'button' } : null

  return (
    <>
      <figure {...bem('', { [type]: type, facsimile }, className)}>
        <div {...bem('aspect', { expandable })} style={styles} ref={triggerRef} {...extraProps}>
          <NextImage
            {...bem('image')}
            src={src}
            alt={alt || ''}
            onLoad={handleLoad}
            ref={imageRef}
            width={width}
            height={0}
          />
        </div>

        {(caption || source || download) && (
          <figcaption {...bem('caption')}>
            {caption && `${caption} `}
            {source && !link && <span {...bem('source')}>Foto: {source}</span>}
            {source && link && (
              <span {...bem('source')}>
                Foto:{' '}
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {source}
                </a>
                .
              </span>
            )}
            {download && (
              <span {...bem('download')}>
                <a href={download.url} target="_blank" rel="noopener noreferrer">
                  {download.title}
                </a>
              </span>
            )}
          </figcaption>
        )}
      </figure>
      {expandable && (
        <Modal
          expanded={!!expanded}
          fromElement={triggerRef?.current}
          onClose={toggle(false)}
          tight={tallImage}
          transparent
        >
          <div {...bem('aspect', 'square')} style={styles}>
            <NextImage
              {...bem('image')}
              src={image.width(SIZES.large).url()}
              alt={alt || ''}
              width={width}
              height={0}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

Image.propTypes = {
  type: T.string,
  alt: T.string,
  caption: T.string,
  source: T.string,
  lqip: T.string,
  dimensions: T.object,
  className: T.string,
  facsimile: T.bool,
  expandable: T.bool,
  download: T.shape({
    title: T.string.isRequired,
    url: T.string.isRequired,
  }),
}
