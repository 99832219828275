/* eslint-disable indent */
import T from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { animateFrom, animateTo } from '../../helpers/animate'
import BEMHelper from '../../helpers/bem'
import Button from '../Button'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)
const CLOSE_TEXT = 'Lukk'
const EASE_OUT = 'cubic-bezier(0.86, 0, 0.4, 1)' // Enter
const EASE_IN = 'cubic-bezier(0.86, 0, 0.4, 1)' // Exit
const KEY_ESC = 27
const ANIMATION_DURATION = 500

export default function Modal({
  children,
  expanded,
  onClose,
  fromElement,
  preventClickOutside,
  article,
  transparent,
  tight,
  slides,
  autoWidth,
}) {
  const initialRender = useRef(true)
  const contentRef = useRef()
  const wrapperRef = useRef()
  const [show, setShow] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)
  const [animation, setAnimation] = useState(null)

  useEffect(() => {
    wrapperRef.current = document.body
  }, [])

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false

      return
    }

    if (show) {
      setAnimation(
        animateFrom({
          from: fromElement,
          to: contentRef.current,
          fromProps: { opacity: 0 },
        }),
      )

      contentRef.current.focus({ preventScroll: true })
    }
  }, [show, fromElement])

  useEffect(() => {
    if (expanded !== show) {
      setShow(expanded)

      if (expanded) {
        document.body.classList.add('block-scrolling')
      }

      if (!expanded) {
        const animation = animateTo({
          to: fromElement,
          from: contentRef.current,
          toProps: { opacity: 0 },
        })
        setAnimation(animation)
        setAnimateOut(true)
      }
    }
  }, [expanded, fromElement, show])

  useEffect(() => {
    let _timer
    if (animateOut) {
      clearTimeout(_timer)

      _timer = setTimeout(() => {
        if (animateOut) {
          setAnimateOut(false)
          setAnimation(null)
          document.body.classList.remove('block-scrolling')
        }
      }, ANIMATION_DURATION)
    }

    return () => {
      clearTimeout(_timer)
      document.body.classList.remove('block-scrolling')
    }
  }, [animateOut])

  const handleKeyDown = useCallback(
    ({ keyCode }) => {
      if (keyCode === KEY_ESC) {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const handleBackdropClick = () => {
    if (preventClickOutside) {
      contentRef.current.focus()
    } else {
      onClose()
    }
  }

  const style = animation
    ? {
        animation: `${animation} ${ANIMATION_DURATION}ms ${animateOut ? EASE_IN : EASE_OUT} both`,
      }
    : { opacity: 0 }

  if (show || animateOut) {
    return createPortal(
      <aside {...bem('', { transparent, tight, article, slides })} role="dialog" aria-modal="true">
        <button
          type="button"
          {...bem('hidden-close')}
          aria-label={CLOSE_TEXT}
          onFocus={handleBackdropClick}
        />
        <Button
          type="button"
          {...bem('close', { 'animate-out': animateOut })}
          onClick={onClose}
          aria-label={CLOSE_TEXT}
          icon="close"
          circle
          primary
        />

        <div
          {...bem('content', { autoWidth: autoWidth })}
          ref={contentRef}
          style={style}
          tabIndex="0"
        >
          {children}
        </div>

        <button
          type="button"
          aria-label={CLOSE_TEXT}
          {...bem('backdrop', {
            'animate-out': animateOut,
            'prevent-close': preventClickOutside,
          })}
          onFocus={handleBackdropClick}
          onClick={handleBackdropClick}
        />
      </aside>,
      wrapperRef.current,
    )
  }

  return null
}

Modal.propTypes = {
  children: T.any.isRequired,
  expanded: T.bool,
  onClose: T.func.isRequired,
  fromElement: T.any,
  preventClickOutside: T.bool,
  transparent: T.bool,
  tight: T.bool,
  article: T.bool,
  slides: T.bool,
}
